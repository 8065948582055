import React from 'react';
import headerStyles from './Header.module.scss';
import kiwanisLogo from '../../img/Kiwanis_logo.png';
import decorImage from '../../img/graphics-in-top-panel.svg';


export default function Header(){
    return (
        <header className={headerStyles.AppHeader}>
            <div className={headerStyles.logoAndTitle}>
                <img className={headerStyles.Logo} src={kiwanisLogo} alt="Kiwanis Logo"/>
                <div className={headerStyles.AppTitle}>Status Dashboard</div>
            </div>
            <div className={headerStyles.Decorations} />
        </header>
    );
}