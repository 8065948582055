import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import SubHeader from "./subheader/SubHeader";
import Api from "../../api/Api";
import IDashboardPageState from "./IDashboardPageState";
import IAppNameAndStatus from "../../api/apps/IAppNameAndStatus";
import moment from 'moment';
import styles from './DashboardPage.module.scss';
import AppStatuses from "../../constants/AppStatuses";


const APP_STATUSES_REFRESH_INTERVAL_MS : number = 15000;

export default function DashboardPage(): React.JSX.Element {

    const [state, setState] = useState<IDashboardPageState>({
        Apps: []
    });

    useEffect(() => {

        let pageRefreshIntervalHandler = FetchAppsDataAndSetupInterval(setState);


        return () =>{
            clearInterval(pageRefreshIntervalHandler);
        };
    }, []);


    return (<>
        <main>
            <SubHeader>
                <Container>
                    <Row>
                        <Col sm={3}>
                            <h1>Column 1</h1>
                        </Col>
                        <Col sm={6}>
                            <h1>Column 2</h1>
                        </Col>
                        <Col sm={3}>
                            <h1>Column 3</h1>
                        </Col>
                    </Row>
                </Container>
            </SubHeader>

            <div className={styles.headerBackground}>
                    <div className={ styles.tableRow}>
                        <div className={styles.colorCell}></div>
                        <div className={"text-start " + styles.appNameCell}>App name</div>
                        <div className={"text-start " + styles.statusCell}>Status</div>
                        <div className={"text-start " + styles.lastCheckedCell}>Last check date/time</div>
                    </div>
            </div>


                    {state.Apps.map((app, index, all) =>(
                        <div key={app.id} className={styles.tableRow + " " + styles.app}>
                                <div className={styles.colorCell + " " + getStatusCssClass(app.status)}>
                                </div>
                                <div className={"text-start " + styles.appNameCell}>
                                    {app.name}
                                </div>
                                <div className={"text-start " + styles.statusCell}>
                                    {app.status}{app.statusDescription && <> - {app.statusDescription}</>}
                                </div>
                                <div className={"text-start " + styles.lastCheckedCell}>
                                     {app.lastCheckedOn !== null &&
                                         <>
                                             {moment(app.lastCheckedOn).format('lll')} {app.lastCheckedTimeZone !== null &&
                                             <>{app.lastCheckedTimeZone}</>
                                             }
                                         </>
                                     }

                                </div>
                        </div>

                    ))}
            </main>
        </>);

}



function getStatusCssClass(status: string): string {
    switch (status){
        case AppStatuses.Ok:
            return styles.statusOk;
        case AppStatuses.Warning:
            return styles.statusWarn;
        case AppStatuses.Error:
            return styles.statusError;
        case AppStatuses.NoInformation:
        default:
            return styles.statusNoInfo;
    }
}


function SetAppsData(apps: IAppNameAndStatus[], setState: React.Dispatch<React.SetStateAction<IDashboardPageState>>) {
    console.log('Setting apps to state...');
    console.log(apps);
    setState(prevState => {
        let newState = {
            ...prevState,
            ...{
                Apps: apps
            }
        };

        return newState;
    });
}

function FetchAppsDataAndSetupInterval(setState: React.Dispatch<React.SetStateAction<IDashboardPageState>>) {
    // let's allow only one request at a time
    let isAlreadyFetchingData = false;

    function pullAppStatusesFromApi(){
        if (!isAlreadyFetchingData) {
            console.log('App statuses refresh should go here.');

            isAlreadyFetchingData = true;
            Api.Apps.index()
                .then(response => {
                    SetAppsData(response.apps, setState);
                    console.log('App statuses have been received.');
                })
                .catch(error => {
                    console.log('An error occurred when trying to fetch app statuses from API.');
                    console.log(error);
                })
                .finally(() => {
                    isAlreadyFetchingData = false;
                });
        }
    }


    pullAppStatusesFromApi();

    let pageRefreshIntervalHandler = setInterval(
        () => {

            pullAppStatusesFromApi();

        },
        APP_STATUSES_REFRESH_INTERVAL_MS
    );
    return pageRefreshIntervalHandler;
}
