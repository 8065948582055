import React from "react";
import IErrorPageProps from "./IErrorPageProps";

export default function ErrorPage(props : IErrorPageProps) : React.JSX.Element{
    return (
        <>
            <h1>{props.title}</h1>
            <p>{props.message}</p>
        </>
    );
}