import React from 'react';
import PageLayoutProps from "./PageLayoutProps";
import Header from "../header/Header";

export default function PageLayout(props: PageLayoutProps) : React.JSX.Element {
    return (
        <>
            <Header/>
            {props.children}
        </>
    );
}