import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import DashboardPage from "../../pages/dashboard-page/DashboardPage";

export default function AppRouter() : React.JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<DashboardPage/>}
                />
            </Routes>
        </BrowserRouter>
    );
}