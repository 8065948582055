import IIndexResponse from "./IIndexResponse";
import {AxiosInstance} from "axios";
import UnexpectedHttpStatusError from "../exceptions/UnexpectedHttpStatusError";

export default class HomeApi {

    private axiosInstance : AxiosInstance;

    constructor(axiosInstance : AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public async index() : Promise<IIndexResponse>{
        let response = await this.axiosInstance.get<IIndexResponse>('/');

        if (response.status === 200){
            return response.data;
        }

        throw UnexpectedHttpStatusError.build(response.status, response.statusText);

    }

}