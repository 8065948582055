import React, {useEffect, useState} from 'react';
import appStyles from './App.module.scss';
import AppRouter from "./components/router/AppRouter";
import IAppState from "./IAppState";
import Api from "./api/Api";
import Settings from "./settings/Settings";
import PageLayout from "./components/layout/PageLayout";
import ErrorPage from "./pages/error-page/ErrorPage";

const API_WAITING_INTERVAL_MS: number = 15000;

function App() {

    const [state, setState] = useState<IAppState>({
        isApiChecked: false, isApiOk: false
    });

    useEffect(() => {

        if (state.isApiChecked && state.isApiOk) {
            return;
        }

        let apiCheckingIntervalId: NodeJS.Timer | null = null;
        let shouldStop : boolean = false;

        checkApiConnectionAndSetState(setState)
            .catch(() => {

                if (!shouldStop) {
                    console.log(
                        'First API check resulted in an error. Lets setup an interval checks to load UI when API gets up and running...');

                    apiCheckingIntervalId = setInterval(async () => {
                        try {
                            await checkApiConnectionAndSetState(setState);
                        } catch {
                        }
                    }, API_WAITING_INTERVAL_MS);
                }
            });


        return () => {
            shouldStop = true;

            if (apiCheckingIntervalId !== null) {
                console.log('Deactivating checks interval because component unmounts...');
                clearInterval(apiCheckingIntervalId);
            }
        };
    }, [state.isApiChecked, state.isApiOk]);

    return (<div className={appStyles.App}>
            <PageLayout>
                {state.isApiChecked ? <>
                    {state.isApiOk ? <AppRouter/> :
                        <ErrorPage title={'API communication error'} message={'Can not get data from server.'}/>}
                </> : <>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border m-5" role="status">

                        </div>
                    </div>
                </>}
            </PageLayout>
        </div>);

    async function checkApiConnectionAndSetState(setState: React.Dispatch<React.SetStateAction<IAppState>>) {
        console.log('checking API...');

        try {
            let result = await checkApiConnection();

            console.log(`API check result is ready: ${result}`);

            setApiCheckResultToState(result, setState);

            if (!result) {
                console.log('API is not Ok, so throwing error.');
                throw new Error('API check has completed, but API is not OK.');
            }

        } catch (error) {

            console.log('API check resulted in error. Marking API as not OK and throwing error...');

            setApiCheckResultToState(false, setState);

            throw error;
        }
    }

}

function setApiCheckResultToState(isApiOk : boolean, setState: React.Dispatch<React.SetStateAction<IAppState>>){
    setState(oldState => {
        let newState = {
            ...oldState, ...{
                isApiChecked: true, isApiOk: isApiOk
            }
        };
        return newState;
    });
}

async function checkApiConnection(): Promise<boolean> {
    try {

        let result = await Api.Home.index();

        if (result.welcomeMessage) {
            return true;
        }

        console.error(`Making a call to home endpoint of API completed successfully, but usual WelcomeMessage field was ` + `missing. Please check if API base URL points to API. Base URL: ${Settings.ApiBaseUrl}`);

        return false;

    } catch (error) {
        console.error(`Making a call to API returned an error. API is either not OK, or base URL is not correct in application ` + `settings. Base URL: ${Settings.ApiBaseUrl}`, error);

        return false;
    }

}


export default App;
