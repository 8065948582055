import HomeApi from "./home/HomeApi";
import AppsApi from "./apps/AppsApi";
import axios, {AxiosRequestConfig} from "axios";
import Settings from "../settings/Settings";

export default class Api{

    public static Home : HomeApi;
    public static Apps : AppsApi;

    public static init() : void{
        let axiosInstance = axios.create({
            baseURL: Settings.ApiBaseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        Api.Home = new HomeApi(axiosInstance);
        Api.Apps = new AppsApi(axiosInstance);
    }
}

Api.init();