import axios, {AxiosInstance} from "axios";
import IIndexResponse from "./IIndexResponse";
import UnexpectedHttpStatusError from "../exceptions/UnexpectedHttpStatusError";

export default class AppsApi {
    private axiosInstance : AxiosInstance;

    constructor(axiosInstance : AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }


    async index() : Promise<IIndexResponse>{

        let response = await this.axiosInstance.get<IIndexResponse>('/api/Apps');

        if (response.status !== 200){
            throw UnexpectedHttpStatusError.build(response.status, response.statusText);
        }

        return response.data;
    }
}