import React from 'react';
import styles from './SubHeader.module.scss';
import SubHeaderProps from "./SubHeaderProps";

export default function SubHeader(props : SubHeaderProps) : React.JSX.Element {
    return (
        <div className={styles.SubHeader}>
            {props.children}
        </div>
    );
}